<template>
  <div :id="$style.saveTracksListModal">
    <slot
      v-bind="{
        showTracksListModal,
        hideTracksListModal,
      }"
    />
    <vue-final-modal
      v-model="isUserTracksListModalOpen"
      name="user-tracks-list-modal"
    >
      <div :class="$style.modalRoot">
        <div :class="$style.modalContent">
          <BaseButton
            :class="$style.headerIconButton"
            @click.prevent="onCloseIconClick"
          >
            <BaseIcon icon="times" :class="$style.headerIcon" />
          </BaseButton>
          <div>
            Coming soon
          </div>
          <!-- <component
            :is="tracksListModalComponent"
            :tracks="tracks"
            :styles="{
              heading: $style.heading,
              inputAsHeading: $style.inputAsHeading,
            }"
            @changeCurrentComponent="
              currentTrackListModalComponentStep = $event
            "
            @close="hideTracksListModal"
            @changeMode="$emit('changeMode', $event)"
            @editList="selectedList = $event"
            :selectedList="selectedList"
          /> -->
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import AddTracksToList from './components/AddTracksToList.vue';
import CreateTracksList from './components/CreateTracksList.vue';
import SearchLists from './components/SearchLists.vue';
import EditList from './components/EditList.vue';

const TRACKS_LIST_MODAL_COMPONENT_STEPS = {
  SAVE_TRACKS: AddTracksToList,
  CREATE_LIST: CreateTracksList,
  SEARCH_LISTS: SearchLists,
  VIEW_TRACKS: '',
  EDIT_LIST: EditList,
};

export default {
  name: 'UserTracksListModal',
  components: {},
  props: {
    tracks: {
      type: Object,
      default: () => {},
    },
    initialComponent: {
      type: String,
      validator: (value) =>
        Object.keys(TRACKS_LIST_MODAL_COMPONENT_STEPS).includes(value),
      default: 'SAVE_TRACKS',
    },
  },
  data() {
    return {
      selectedList: null,
      isUserTracksListModalOpen: false,
      currentTrackListModalComponentStep: this.initialComponent,
    };
  },
  computed: {
    tracksListModalComponent() {
      return TRACKS_LIST_MODAL_COMPONENT_STEPS[
        this.currentTrackListModalComponentStep
      ];
    },
  },
  methods: {
    onCloseIconClick() {
      if (
        ['SAVE_TRACKS', 'SEARCH_LISTS'].includes(
          this.currentTrackListModalComponentStep
        )
      ) {
        this.hideTracksListModal();
        return;
      }

      const nextComponentMap = {
        CREATE_LIST: 'SAVE_TRACKS',
        VIEW_TRACKS: 'SEARCH_LISTS',
        EDIT_LIST: 'SEARCH_LISTS',
      };

      const nextComponent =
        nextComponentMap[this.currentTrackListModalComponentStep];

      if (nextComponent) {
        this.currentTrackListModalComponentStep = nextComponent;
      } else {
        this.hideTracksListModal();
      }
    },
    showTracksListModal(component) {
      this.currentTrackListModalComponentStep = component;
      this.isUserTracksListModalOpen = true;
    },
    hideTracksListModal() {
      this.isUserTracksListModalOpen = false;
    },
  },
};
</script>
<style lang="scss" module>
.modalRoot {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background: white;
  min-width: min(600px, 90vw);
  max-width: 1065px;
  height: min(32rem, 85vh);
  padding: 4rem 4rem;
  position: relative;
}
.headerIconButton {
  padding: 0 !important;
  min-width: auto !important;
  position: absolute;
  font-size: 1.6rem;
  top: 2rem;
  right: 2rem;
  font-size: 2rem !important;
  color: $color-grey-light !important;
  &:hover {
    color: $color-primary !important;
  }
}
.heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.inputAsHeading {
  border: none;
}
</style>
