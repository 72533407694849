<template>
  <div :id="$style.searchLists">
    <input
      :class="[styles.heading, styles.inputAsHeading]"
      v-model="query"
      placeholder="Search lists..."
    />
    <div v-if="searchListsStatusPending">Loading...</div>
    <div v-else-if="searchListsStatusError">
      There was a problem with loading the lists
    </div>
    <ul v-else-if="lists.length" :class="$style.listWrapper">
      <li v-for="list of lists" :key="list.id" :class="$style.listItem">
        <div :class="$style.listNameWrapper">
          <div>
            <BaseButton variant="icon" @click="onEditList(list)">
              <BaseIcon :class="$style.editIcon" icon="pencil-alt" />
            </BaseButton>
          </div>
          <div>
            <BaseButton
              :class="$style.listNameBtn"
              @click="onListClick(list)"
              >{{ list.name }}</BaseButton
            >
          </div>
        </div>
        <div>
          {{ list.tracksCount }}
        </div>
      </li>
      <li>
        <LoadMore @loadMore="onLoadMore" />
      </li>
    </ul>
  </div>
</template>
<script>
import { apiStatusComputedFactory } from '@/api/helpers/apiStatusComputedFactory';
import { withAsync } from '@/helpers';
import { API_STATUS } from '@/constants/apiStatus';
import { fetchUserTracksLists } from '@/api/userTracksListApi';
import { debounce } from 'lodash-es';
import { LoadMore } from '@/components/common';

const { IDLE, PENDING, SUCCESS, ERROR, LOADING_MORE } = API_STATUS;

export default {
  name: 'SearchLists',
  components: {
    LoadMore,
  },
  props: {
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      query: '',
      lists: [],
      searchListsStatus: IDLE,
      page: 0,
      isLastPage: false,
    };
  },
  computed: {
    ...apiStatusComputedFactory(['searchListsStatus']),
  },
  watch: {
    query(query) {
      this.page = 0;
      this.isLastPage = false;
      this.searchListsDebounced(query, 0);
    },
  },
  methods: {
    onListClick(list) {
      console.log('list', list);
      this.$emit('changeMode', {
        mode: 'list',
        value: list,
      });
      this.$emit('close');
    },
    onEditList(list) {
      this.$emit('editList', list);
      this.$emit('changeCurrentComponent', 'EDIT_LIST');
    },
    onLoadMore() {
      console.log('on load more');
      if (this.isLastPage) return;

      this.searchListsDebounced(this.query, this.page + 1);
    },
    async searchLists(query, page = 0) {
      // Bail out if searchLists is currently being performed
      if ([PENDING, LOADING_MORE].includes(this.searchListsStatus)) {
        return;
      }
      this.page = page;
      const perPage = 20;
      this.searchListsStatus = page === 0 ? PENDING : LOADING_MORE;
      const { response, error } = await withAsync(() =>
        fetchUserTracksLists({
          query,
          page,
          perPage,
        })
      );

      console.log(response, error);

      if (error) {
        this.searchListsStatus = ERROR;
        return;
      }
      this.lists =
        page === 0 ? response.lists : [...this.lists, ...response.lists];

      if (response.lists.length < perPage) {
        this.isLastPage = true;
      }
      this.searchListsStatus = SUCCESS;
    },
  },
  created() {
    this.searchListsDebounced = debounce(this.searchLists, 500);
    // this.searchListsDebounced(this.query, this.page);
  },
};
</script>
<style lang="scss" module>
.listWrapper {
  margin-top: 1.5rem;
  padding: 0;
  max-height: 28rem;
  overflow-y: auto;
}

#searchLists {
  .listNameBtn {
    text-align: left;
    padding: 0;
    color: $color-text;
    &:hover {
      cursor: pointer;
      color: $color-primary;
    }
  }
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + .listItem {
    margin-top: 1.5rem;
  }
}

.listNameWrapper {
  display: flex;
  gap: 1rem;
}

.editIcon {
  color: rgba(207, 208, 223, 1);
  &:hover {
    color: $color-primary;
  }
}
</style>
