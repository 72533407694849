<template>
  <div :id="$style.addTracksToList">
    <div v-if="fetchListsStatusPending">Loading..</div>
    <div v-else :class="$style.listsContentWrapper">
      <h2 :class="styles.heading">Save To List . . .</h2>
      <ul :class="$style.list">
        <li v-for="list of lists" :key="list.id">
          <button
            :class="[
              $style.listItem,
              selectedList === list.id && $style.selected,
            ]"
            @click="onSelectList(list)"
          >
            <div>
              {{ list.name }}
            </div>
            <div>
              {{ list.tracksCount }}
            </div>
          </button>
        </li>
      </ul>
      <p v-if="saveTracksToListStatusSuccess">Tracks saved...</p>
      <BaseButton
        :class="[
          $style.ctaBtn,
          selectedList ? $style.saveBtn : $style.createListBtn,
        ]"
        @click="selectedList ? saveTracks() : showCreateNewList()"
      >
        {{
          selectedList
            ? saveTracksToListStatusPending
              ? 'SAVING...'
              : 'SAVE'
            : 'CREATE A NEW LIST'
        }}
      </BaseButton>
    </div>
  </div>
</template>
<script>
import { apiStatusComputedFactory } from '@/api/helpers/apiStatusComputedFactory';
import { withAsync } from '@/helpers';
import { API_STATUS } from '@/constants/apiStatus';
import {
  fetchUserTracksLists,
  addTracksToUserList,
} from '@/api/userTracksListApi';
const { IDLE, PENDING, SUCCESS, ERROR, LOADING_MORE } = API_STATUS;

export default {
  name: 'AddTracksToList',
  props: {
    tracks: {
      type: Object,
      default: () => ({}),
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      lists: [],
      fetchListsStatus: IDLE,
      saveTracksToListStatus: IDLE,
      selectedList: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory('fetchListsStatus'),
    ...apiStatusComputedFactory('saveTracksToListStatus'),
  },

  methods: {
    async saveTracks() {
      this.saveTracksToListStatus = PENDING;
      const { response, error } = await withAsync(() =>
        addTracksToUserList({
          listId: this.selectedList,
          objectIDs: Object.values(this.tracks).map((track) => track.objectID),
        })
      );

      if (error) {
        this.saveTracksToListStatus = ERROR;
        return;
      }
      this.lists = this.lists.map((list) => {
        if (list.id !== this.selectedList) {
          return list;
        }

        return {
          ...list,
          tracksCount: list.tracksCount + response.tracksInserted,
        };
      });
      this.selectedList = null;
      this.saveTracksToListStatus = SUCCESS;
      this.$emit('close');
    },
    showCreateNewList() {
      this.$emit('changeCurrentComponent', 'CREATE_LIST');
    },
    onSelectList(list) {
      if (list.id === this.selectedList) {
        this.selectedList = null;
        return;
      }
      this.selectedList = list.id;
    },
    async fetchUserTracksLists() {
      this.fetchListsStatus = PENDING;

      const { response, error } = await withAsync(() => fetchUserTracksLists());

      if (error) {
        this.fetchListsStatus = ERROR;
        return;
      }
      this.lists = response.lists;
      this.fetchListsStatus = SUCCESS;
    },
    initLists() {
      if (!this.lists.length && this.fetchListsStatusIdle) {
        this.fetchUserTracksLists();
      }
    },
  },
  created() {
    // this.initLists();
  },
};
</script>
<style lang="scss" module>
.list {
  list-style: none;
  padding: 0;
  max-height: 22rem;
  overflow-y: auto;
}
.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.25rem;

  &:hover {
    background-color: #f3f4f6;
  }

  &.selected {
    // background-color: $color-accent;
    color: $color-primary;
  }

  & + .listItem {
    margin-top: 1rem;
  }
}

#addTracksToList {
  height: 100%;
  .ctaBtn {
    padding: 1rem;
    width: 100%;
    margin-top: auto;
  }

  .saveBtn {
    background-color: $color-primary;
    color: white;
  }

  .createListBtn {
    background-color: $color-accent;
    color: black;
  }
}

.listsContentWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
